<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <div @click="returnToPreviousPage(true)" style="cursor: pointer;">
            <icon icon="#cx-hea1-arrow-left" />
          </div>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.add-order") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              submitFormState = true;
              initSubmit();
            "
            v-if="selectedOrderItems.length"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="resourceDetails">
      <li class="clebex-item-section-item full-right-underline">
        <div
          class="clebex-item-content-wrapper"
          @click="showInfoBox = !showInfoBox"
          style="cursor: pointer;"
        >
          <div
            class="clebex-section-input"
            style="cursor: pointer;"
            @click="showInfoBox = !showInfoBox"
          >
            <label
              class="clebex-section-input-label"
              for="orderNumber"
              style="cursor: pointer;"
              @click="showInfoBox = !showInfoBox"
              >{{ displayLabelName("services.services.order-number") }}</label
            >
            <span
              style="cursor: pointer; color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              @click="showInfoBox = !showInfoBox"
            >
              {{ resourceDetails.data.order_id }}</span
            >
          </div>
          <span class="follow-up-icon-item" style="cursor: pointer;">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              style="cursor: pointer; margin-right: 15px; margin-top: 5px;"
              :style="[
                !showInfoBox
                  ? 'transform: rotate(90deg)'
                  : 'transform: rotate(-90deg)',
              ]"
            />
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ resourceDetails.data.path }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.address")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ resourceDetails.data.address }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="requestBy">{{
              displayLabelName("services.services.request-by")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="requestBy"
              disabled="true"
              v-model="resourceDetails.data.request_by"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="datetime">{{
              displayLabelName("services.services.created-at")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="datetime"
              disabled="true"
              v-model="resourceDetails.data.created_at"
            />
          </div>
        </div>
      </li>
    </ul>
    <VeeForm
      :key="formKey"
      ref="addServiceOrderForm"
      @submit="submitForm"
      class="form inline-input edit-form"
      novalidate
      style="height: auto;"
    >
      <ul class="clebex-item-section pill" v-if="listOfServiceOrderItems">
        <!-- Date from - date to -->
        <span class="delivery-period">{{
          displayLabelName("services.services.date-time")
        }}</span>
        <br /><br />
        <li
          class="clebex-item-section-item"
          style="margin-bottom: 0px; cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour from - hour to -->
        <li
          class="clebex-item-section-item time"
          style="cursor: pointer;"
          @click="showDateTimePicker = !showDateTimePicker"
        >
          <div
            class="clebex-item-content-wrapper"
            style="border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="showDateTimePicker"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>

      <!-- Selected service items -->

      <span
        class="order-level"
        style="margin-left: 15px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
        >{{ displayLabelName("services.services.selected-items") }}</span
      >
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 10px;"
        v-if="selectedOrderItems && selectedOrderItems.length"
      >
        <li
          class="clebex-item-section-item"
          v-for="selectedOrderItem in selectedOrderItems"
          :key="selectedOrderItem.id"
        >
          <div style="display: flex; flex-direction: row;">
            <button class="clebex-item-content-wrapper">
              <span
                class="label"
                @click="openItemDetails(selectedOrderItem.id)"
              >
                <span class="highlight">{{ selectedOrderItem.name }} </span>
                &nbsp;
                <span v-if="selectedOrderItem.quantity" class="highlight"
                  >({{ selectedOrderItem.quantity }})</span
                >
              </span>
              <span class="follow-up-icons">
                <span
                  class="follow-up-icon-item"
                  v-if="selectedOrderItem.id == $route.params.order_item_id"
                  @click="selectOrderItem(selectedOrderItem)"
                >
                  <icon icon="#cx-app1-checkmark"></icon>
                </span>
              </span>
            </button>
            <span
              class="follow-up-icons service-item-info"
              style="cursor: pointer;"
              @click="openServiceItemInfo(selectedOrderItem.id)"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-information"></icon>
              </span>
            </span>
          </div>
        </li>
      </ul>

      <!-- Service items -->

      <span class="order-level" style="margin-top: 20px; margin-left: 15px;">{{
        displayLabelName("services.service-items.service-items")
      }}</span>
      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 0px;"
        v-for="(serviceOrderType, index) in listOfServiceOrderItems"
        :key="index"
      >
        <div
          class="service-order-type"
          @click="showHideType(serviceOrderType.id)"
        >
          <span class="order-level"
            >{{ serviceOrderType.name }} ({{
              selectedOrderItems.filter(
                (el) => el.service_type_id == serviceOrderType.id
              ).length
            }}/{{ serviceOrderType.items.length }})</span
          ><icon
            class="order-type-icon"
            icon="#cx-hea1-arrow-left"
            :style="[
              showItemType(serviceOrderType.id)
                ? 'transform: rotate(90deg)'
                : 'transform: rotate(-90deg)',
            ]"
          />
        </div>
        <div v-if="showItemType(serviceOrderType.id)" style="margin-top: 4px;">
          <li
            class="clebex-item-section-item"
            v-for="serviceOrderItem in serviceOrderType.items"
            :key="serviceOrderItem.id"
          >
            <div
              style="display: flex; flex-direction: row;"
              v-if="
                !selectedOrderItems.find((el) => el.id == serviceOrderItem.id)
              "
            >
              <button
                class="clebex-item-content-wrapper"
                @click="
                  selectOrderItem(serviceOrderItem);
                  openItemDetails(serviceOrderItem.id);
                  setDates();
                "
              >
                <span class="label">
                  <span class="highlight">{{ serviceOrderItem.name }}</span>
                </span>
                <span class="follow-up-icons">
                  <span
                    class="follow-up-icon-item"
                    v-if="checked(serviceOrderItem.id)"
                  >
                    <icon icon="#cx-app1-checkmark"></icon>
                  </span>
                </span>
              </button>
              <span
                class="follow-up-icons service-item-info"
                style="cursor: pointer;"
                @click="openServiceItemInfo(serviceOrderItem.id)"
              >
                <span class="follow-up-icon-item">
                  <icon icon="#cx-app1-information"></icon>
                </span>
              </span>
            </div>
          </li>
        </div>
      </ul>

      <!-- Error messages -->

      <!-- <span
        class="order-level"
        style="margin-top: 20px; margin-left: 15px; color: red;"
        v-for="cs in canSave"
        v-bind:key="cs"
        >{{ cs }}</span
      > -->
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <!-- Modal for going back -->
    <screen-modal
      class="confirm-modal"
      type="success"
      :hideTimer="true"
      :confirm-action="saveDataFromConfirm"
      :confirm-button-label="displayLabelName('global.buttons.yes')"
      :cancelButtonLabel="displayLabelName('global.buttons.no')"
      :show="showConfirmation"
      @close="returnToPreviousPageConfirmed"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("services.services.would-you-like-to-save-the-data")
        }}
      </h3>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { DateTime } from "luxon";

export default {
  name: "AddOrder",
  data() {
    return {
      submitFormState: false,
      showDateTimePicker: false,
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: this.getInitTime(),
      selectedHourTo: this.getInitTime(1),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      listOfShowHideTypes: null,
      listOfShowHideSelectedItems: [],
      showInfoBox: false,
      canSave: [],
      saveInitial: false,
      showConfirmation: false,
      switchResourceId: null,
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceAttributes",
      "resourceServices",
      "selectedResourceAttributes",
      "selectedResourceServices",
    ]),
    ...mapState("service", [
      "serviceResource",
      "resourceDetails",
      "serviceOrderType",
      "serviceOrderItems",
      "selectedOrderItems",
      "serviceOrderCostCode",
      "serviceOrderFrom",
      "serviceOrderTo",
      "infobox",
    ]),
    ...mapState("bookings", ["booking"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),

    listOfServiceOrderItems() {
      if (this.serviceOrderItems && this.serviceOrderItems.data) {
        return this.serviceOrderItems.data;
      }
      return null;
    },
    routeParam() {
      return this.$route.params.order_resource_id;
    },
    routeParamType() {
      return this.$route.params.order_type_id;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    routeParam() {
      this.switchResourceId = this.routeParam;
      this.returnToPreviousPage();
    },
    routeParamType() {
      if (this.routeParamType) {
        this.selectOrderItem([]);
        if (
          this.$route.params.order_resource_id &&
          this.$route.params.order_type_id
        ) {
          this.getServiceOrderItems(this.$route.params.order_resource_id);
          this.getResourceDetails(this.$route.params.order_resource_id);
        } else {
          this.$router.push({
            name: "r_services",
          });
        }
      }
    },
    serviceOrderItems() {
      this.listOfShowHideTypes = this.serviceOrderItems.data.map((item) => ({
        id: item.id,
        show: this.$route.params.order_type_id == item.id ? true : false,
      }));
    },
    booking() {
      if (this.booking) {
        this.selectedDateFrom = DateTime.fromISO(
          this.booking.datetime_from
        ).toFormat("yyyy-dd-MM");
        this.selectedDateTo = DateTime.fromISO(
          this.booking.datetime_to
        ).toFormat("yyyy-dd-MM");
        this.selectedDateFromParsed = DateTime.fromISO(
          this.booking.datetime_from
        ).toFormat("yyyy-MM-dd");
        this.selectedDateToParsed = DateTime.fromISO(
          this.booking.datetime_to
        ).toFormat("yyyy-MM-dd");
        this.selectedHourFrom = DateTime.fromISO(
          this.booking.datetime_from
        ).toFormat("HH:mm");
        this.selectedHourTo = DateTime.fromISO(
          this.booking.datetime_to
        ).toFormat("HH:mm");
      }
    },
    selectedDateFrom() {
      this.setDates();
    },
    selectedDateTo() {
      this.setDates();
    },
    selectedHourFrom() {
      this.setDates();
    },
    selectedHourTo() {
      this.setDates();
    },
    selectedOrderItems: {
      handler(oldVal, newVal) {
        if (this.saveInitial == false) {
          this.saveInitial = oldVal.length == 0 ? false : true;
        }

        this.canSave = [];
        newVal.forEach((item) => {
          if (
            item.cost_code_required &&
            (item.cost_code == "" || item.cost_code == null)
          ) {
            this.canSave.push(
              item.name +
                " - " +
                this.displayLabelName("services.services.cost-code-is-missing")
            );
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("resource", [
      "getResourceServices",
      "selectResourceService",
      "setSelectedResourceServices",
    ]),
    ...mapActions("service", [
      "getServiceOrders",
      "getResourceDetails",
      "setServiceOrderType",
      "getServiceOrderItems",
      "setServiceOrderItems",
      "selectOrderItem",
      "setServiceOrders",
      "setPage",
      "setTotalPages",
      "setServiceOrderFrom",
      "setServiceOrderTo",
    ]),
    ...mapActions("bookings", ["getBooking"]),
    initialize() {
      this.selectOrderItem([]);
      if (this.$route.params.order_resource_id) {
        this.getServiceOrderItems(this.$route.params.order_resource_id);
        this.getResourceDetails(this.$route.params.order_resource_id);
        this.setDates();

        if (this.$route.params.order_booking_id) {
          this.getBooking({ id: this.$route.params.order_booking_id });
        }
      } else {
        this.$router.push({
          name: "r_services",
        });
      }
    },
    saveDataFromConfirm() {
      this.submitFormState = true;
      this.initSubmit();
    },
    setDates() {
      this.setServiceOrderFrom(
        `${DateTime.fromISO(
          this.selectedDateFromParsed
        ).toISODate()}T${DateTime.fromISO(this.selectedHourFrom).toFormat(
          "HH:mm"
        )}:00`
      );
      this.setServiceOrderTo(
        `${DateTime.fromISO(
          this.selectedDateToParsed
        ).toISODate()}T${DateTime.fromISO(this.selectedHourTo).toFormat(
          "HH:mm"
        )}:00`
      );
      if (this.selectedOrderItems) {
        this.selectedOrderItems.forEach((item) => {
          let x = item;
          if (x.requested_time_from == null) {
            x.requested_time_from = this.serviceOrderFrom;
          }
          if (x.requested_time_to == null) {
            x.requested_time_to = this.serviceOrderTo;
          }
        });
      }
    },
    showItemType(id) {
      if (this.listOfShowHideTypes) {
        return this.listOfShowHideTypes.find((item) => item.id == id).show;
      }
      return false;
    },
    showHideType(id) {
      var showHide = this.listOfShowHideTypes.find((item) => item.id == id)
        .show;
      this.listOfShowHideTypes.find((item) => item.id == id).show = !showHide;
    },
    openItemDetails(id) {
      if (this.$route.name.includes("add-booking-order")) {
        this.$router.push({
          name: "r_services-add-booking-order-item-details",
          params: {
            order_item_id: id,
          },
        });
      } else if (this.$route.name.includes("resources")) {
        this.$router.push({
          name: "r_services-resources-add-order-item-details",
          params: {
            order_item_id: id,
          },
        });
      } else {
        this.$router.push({
          name: "r_services-add-order-item-details",
          params: {
            order_item_id: id,
          },
        });
      }
    },
    returnToPreviousPage(back = false) {
      if (this.selectedOrderItems.length > 0) {
        this.showConfirmation = true;
      } else {
        this.setServiceOrderType(null);
        if (this.$route.params.order_resource_id != null && back == false) {
          this.showConfirmation = false;
          this.initialize();
        } else {
          this.$router.push({
            name: this.backLinkName,
            params: this.$route.params,
          });
        }
      }
    },
    returnToPreviousPageConfirmed() {
      this.setServiceOrderType(null);
      if (this.switchResourceId != null) {
        this.showConfirmation = false;
        this.initialize();
        // this.switchResourceId = null;
      } else {
        this.$router.push({
          name: this.backLinkName,
          params: this.$route.params,
        });
      }
    },
    openServiceItemInfo(orderItemId) {
      var route = this.$route.params.order_resource_id
        ? "r_services-resources-add-order-item-info"
        : "r_services-add-order-item-info";

      if (
        this.$route.name == "r_services-add-booking-order" ||
        this.$route.name == "r_services-add-booking-order-item-info"
      ) {
        route = "r_services-add-booking-order-item-info";
      }

      this.$router.push({
        name: route,
        params: {
          order_item_id: orderItemId,
        },
      });
    },
    getInitTime(offset = 0) {
      let minutes =
        (5 * Math.round(parseInt(DateTime.now().toFormat("mm")) / 5)) % 60;
      /**
       * Convert 5 to 05 (when current minute is lesst then `08`)
       * "en-US" is irrelevant, it can be undefined also
       */
      minutes = minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      const hours = DateTime.now()
        .plus({ hours: offset })
        .toFormat("HH");

      return `${hours}:${minutes}`;
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    checked(id) {
      return (
        this.selectedOrderItems &&
        this.selectedOrderItems.length &&
        this.selectedOrderItems.find((item) => item.id === id)
      );
    },
    formValues() {
      let response = [];

      for (var i = 0; i < this.selectedOrderItems.length; i++) {
        let object = {
          quantity: null,
          note: "",
          requested_time_from: null,
          requested_time_to: null,
          cost_code: "",
          service_status: "ORDER",
        };
        object.id = this.selectedOrderItems[i].id;

        object.quantity = this.selectedOrderItems[i].quantity;
        object.note = this.selectedOrderItems[i].note;
        object.requested_time_from = this.selectedOrderItems[
          i
        ].requested_time_from;
        object.requested_time_to = this.selectedOrderItems[i].requested_time_to;
        object.cost_code = this.selectedOrderItems[i].cost_code;

        response.push(object);
      }
      return response;
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values && this.submitFormState) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      this.submitFormState = false;
      values = {
        resource_id: this.$route.params.order_resource_id
          ? this.$route.params.order_resource_id
          : this.serviceResource,
        service_status_id: 1,
        service_items: this.formValues(),
        delivery_start: dateTimeFrom,
        delivery_end: dateTimeTo,
        declaration_id: this.$route.params.order_booking_id
          ? this.$route.params.order_booking_id
          : null,
      };
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .post(apiEndpoints.company.serviceOrders, values)
        .then(() => {
          localStorage.removeItem("costCode");
          this.setPage(null);
          this.setTotalPages(null);
          this.getServiceOrders();

          if (this.switchResourceId != null) {
            this.$router.push({
              name: "r_services-resources-add-order",
              params: {
                order_resource_id: this.switchResourceId,
              },
            });
            this.switchResourceId = null;
          } else {
            this.$router.push({
              name: "r_services",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
  },
};
</script>
<style>
.order-wrapper {
  border: 1px solid lightgray !important;
  border-top: 0px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 3px;
}
.order-item {
  border-radius: 0px !important;
  background: transparent !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}
.order-label {
  padding-left: 0px !important;
}
.order-field {
  background: white !important;
  padding: 5px !important;
  height: 2rem !important;
}
.service-item-info {
  background-color: white;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}
.delivery-period {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
.order-type-icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  transform: rotate(-90deg);
  margin-right: 15px;
}
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0px !important;
  margin-left: 7px;
}
.service-order-type {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 0.8rem;
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  align-items: center;
}
.change-quantity {
  border: 1px solid lightgray;
  padding-top: 1px;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
